import client from '@/http/client';

export function sendEmail(email) {
  return client
    .post('/auth/password/reset/start/', { email })
    .then(() => true)
    .catch(err => {
      console.error(err);
      return false;
    });
}

export function checkToken(token) {
  if (token) {
    return client
      .post('/auth/password/reset/check/', { data: token })
      .then(data => data.userId)
      .catch(() => false);
  }

  return false;
}

export function updatePassword(userId, data) {
  return client
    .patch(`/auth/password/reset/${userId}/`, data)
    .then(() => true)
    .catch(() => false);
}
