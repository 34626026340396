<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-avatar size="32" color="secondary" v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-account</v-icon>
      </v-avatar>
    </template>

    <!-- @todo Реализовать переходы когда появится бэк -->
    <v-list width="250">
      <v-list-item-group>
        <v-list-item :to="$options.profileLink" class="primary--text">
          {{ $t('profile.profile') }}
        </v-list-item>
        <v-list-item @click="logout">
          {{ $t('profile.exit') }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// node_modules
import { mapActions } from 'vuex';

// Constants
import { PROFILE, LOGIN } from '@/constants/routes';
import { LOGOUT } from '@/store/modules/user/actions/types';
import { LOG_OUT as analyticsActionLogout } from '@/constants/analyticsActions';

// Services
import analyticsService from '@/services/analytics';

export default {
  name: 'LayoutHeaderProfile',

  profileLink: { name: PROFILE },

  methods: {
    ...mapActions('user', {
      userLogout: LOGOUT,
    }),

    logout() {
      analyticsService.track(analyticsActionLogout);
      this.userLogout();
      this.$router.push({ name: LOGIN });
    },
  },
};
</script>
