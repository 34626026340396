/* eslint-disable */
import client from '@/http/client';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import argoLogo from '@/assets/img/argo-logo.svg';

const BASE_URL = '/organizations';

class CompanyService {
  constructor() {
    this.companyPromise = null;
  }

  async getLogoUri() {
    const company = await this.getCompany();

    return company.logo;
  }

  async getCompany() {
    if (!this.companyPromise) {
      this.companyPromise = client.get(`${BASE_URL}/management-company/`, {
        _withoutAuthHeader: true,
      });
    }

    try {
      const company = await this.companyPromise;

      return {
        ...company,
        logo: getBaseUrlWithoutApi() + company.logo,
      };
    } catch {
      return {
        logo: argoLogo,
        phone: '+32892312213',
        email: 'info@argo.properties',
        address: 'Lemesou, 132, Stovolos, 2015, Nicosia',
      };
    }
  }

  getSettingsFlag() {
    return client.get(`${BASE_URL}/management-company/settings/flags/`);
  }

  getManagementCompanySettings(config) {
    return client.get(`${BASE_URL}/management-company/settings/`, config);
  }

  patchManagementCompanySettings({ automaticInvoiceNumbering, invoiceNumberTemplates, cancelToken } = {}) {
    return client.patch(
      `${BASE_URL}/management-company/settings/`,
      {
        automaticInvoiceNumbering,
        invoiceNumberTemplates,
      },
      {
        cancelToken,
      }
    );
  }
}

export default new CompanyService();
