<template>
  <v-tabs ref="tabs" class="layout-header-navigation d-none d-sm-none d-md-block" centered hide-slider>
    <v-tab
      v-for="route in firstRoutes"
      :key="route.id"
      link
      class="layout-header-navigation__tab"
      :class="{ 'layout-header-navigation__tab--active': checkActive(route.to.name) }"
      :to="route.to"
    >
      {{ route.text }}
      <v-avatar v-if="route.badge" size="20" class="ml-2 d-flex align-center justify-center" color="secondary">
        <span class="primary--text text-subtitle-2">
          {{ trimBadge(route.badge) }}
        </span>
      </v-avatar>
    </v-tab>

    <v-menu v-if="isTrim" offset-y left>
      <template #activator="{ on, attrs }">
        <v-tab
          class="layout-header-navigation__tab"
          :class="{ 'layout-header-navigation__tab--active': isTabActive }"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('navigation.more') }}
          <v-icon right>mdi-chevron-down </v-icon>
        </v-tab>
      </template>

      <v-list>
        <v-list-item-group>
          <v-list-item v-for="route in otherRoutes" :key="route.id" link class="primary--text" :to="route.to">
            {{ route.text }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-tabs>
</template>

<script>
// Constants
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';

const DISPLAYED_MENU_ITEMS = 3;

export default {
  name: 'LayoutHeaderNavigation',

  props: {
    routes: { type: Array, default: () => [] },
  },

  data() {
    return {
      trimMinWidth: 0,
      isTrim: false,
    };
  },

  computed: {
    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    firstRoutes() {
      if (this.isTrim) {
        return this.routes.slice(0, DISPLAYED_MENU_ITEMS);
      }

      return this.routes;
    },

    otherRoutes() {
      return this.routes.slice(DISPLAYED_MENU_ITEMS);
    },

    isTabActive() {
      const routes = [...this.otherRoutes.map(route => route.to.name)];

      return !!this.$route.matched.find(match => routes.includes(match.name));
    },
  },

  watch: {
    isTrim(newValue) {
      this.$emit('update:is-trim', newValue);
    },
  },

  mounted() {
    this.$options.resizeObserver = new ResizeObserver(this.onResize);

    this.$options.resizeObserver.observe(this.$refs.tabs.$el);
  },

  beforeDestroy() {
    this.$options.resizeObserver.disconnect();
  },

  methods: {
    checkActive(routeName) {
      return !!this.$route.matched.find(match => match.name === routeName);
    },

    trimBadge(value) {
      if (value > 9) {
        return '9+';
      }

      return value;
    },

    onResize() {
      const { clientWidth, scrollWidth } = this.$refs.tabs.$el;

      if (this.trimMinWidth !== 0 && clientWidth >= this.trimMinWidth) {
        this.isTrim = false;
        this.trimMinWidth = 0;
      } else if (clientWidth < scrollWidth) {
        this.trimMinWidth = scrollWidth;
        this.isTrim = true;
      }
    },
  },

  resizeObserver: null,
};
</script>

<style lang="scss">
.layout-header-navigation {
  height: 100%;
  overflow: auto;

  & > .v-item-group {
    height: 100%;
    background: none !important;
  }

  .v-slide-group__wrapper {
    flex-shrink: 0;
  }

  &__tab {
    color: rgba($--secondary-color, 0.5) !important;

    &--active {
      color: $--primary-color !important;
    }
  }
}
</style>
