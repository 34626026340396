<template>
  <v-footer padless class="layout-footer py-6 pb-16 pb-md-6 px-0" :class="{ 'ml-md-15': hasProfile }">
    <v-container class="layout-footer__container py-0 mx-0">
      <div class="d-flex align-start align-lg-center justify-space-between flex-column-reverse flex-lg-row">
        <div>
          <a :href="$options.landingPageUrl" target="_blank">
            {{ $options.copyright }}
          </a>
        </div>

        <div class="d-md-flex mb-4 mb-lg-0">
          <div class="d-flex align-center">
            <v-icon color="rgba(0, 0, 141, 0.5)" class="mr-2">mdi-phone-classic</v-icon>
            <a :href="`tel:${phone}`">
              {{ $options.formatPhoneNumber(phone) }}
            </a>
          </div>

          <div class="ml-md-6 mt-4 mt-md-0 d-flex align-center">
            <v-icon color="rgba(0, 0, 141, 0.5)" class="mr-2">mdi-email</v-icon>
            <a :href="`mailto:${email}`">
              {{ email }}
            </a>
          </div>

          <div class="ml-md-6 mt-4 mt-md-0 d-flex align-center">
            <v-icon color="rgba(0, 0, 141, 0.5)" class="mr-2">mdi-map-marker</v-icon>
            <span>
              {{ address }}
            </span>
          </div>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
// Services
import companyService from '@/services/company';

// Utils
import { formatPhoneNumber } from '@/utils/phone';

const landingPageUrl = process.env.VUE_APP_LANDING_PAGE_URL ?? 'https://alma-app.com/';

export default {
  name: 'LayoutFooter',

  props: {
    hasProfile: { type: Boolean, default: false },
  },

  inject: ['media'],

  formatPhoneNumber,

  landingPageUrl,

  data: () => ({
    phone: '',
    email: '',
    address: '',
  }),

  computed: {},

  async mounted() {
    ({ phone: this.phone, email: this.email, address: this.address } = await companyService.getCompany());
  },

  copyright: '© Powered by ALMA',
};
</script>

<style lang="scss">
.layout-footer {
  background: $--footer-bg-color !important;
  margin-top: 80px;

  @media (max-width: map.get($--screens, 'sm')) {
    margin-top: 40px;
  }

  &,
  a {
    color: $--footer-color !important;
    text-decoration: none;
  }

  &__container {
    padding-left: 12px !important;
    padding-right: 12px !important;
    max-width: 100% !important;

    @media (min-width: map.get($--screens, 'sm')) {
      padding-left: 64px !important;
      padding-right: 64px !important;
    }

    @media (min-width: map.get($--screens, 'md')) {
      padding-left: 160px !important;
      padding-right: 160px !important;
    }
  }
}
</style>
