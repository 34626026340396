<template>
  <v-menu v-model="isOpen" content-class="layout-header-language" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn plain v-bind="attrs" v-on="on">
        {{ language.code }} <v-icon>{{ menuArrowIcon }}</v-icon>
      </v-btn>
    </template>

    <v-list width="250">
      <v-list-item-group v-model="languageModel" mandatory>
        <v-list-item
          v-for="_language in languages"
          :key="_language.id"
          v-ripple="{ class: `primary--text` }"
          class="layout-header-language__item"
          color="primary"
        >
          {{ _language.code }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LayoutHeaderLanguage',

  model: {
    prop: 'language',
    event: 'update:language',
  },

  props: {
    languages: { type: Array, default: () => [] },
    language: { type: Object, default: () => ({}) },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    languageModel: {
      get() {
        const foundIndex = this.languages.findIndex(language => language.id === this.language.id);

        return foundIndex;
      },

      set(index) {
        if (index !== undefined) {
          this.$emit('update:language', this.languages[index]);
        }
      },
    },

    menuArrowIcon() {
      return this.isOpen ? 'mdi-menu-up' : 'mdi-menu-down';
    },
  },
};
</script>

<style lang="scss">
.layout-header-language {
  &__item {
    text-transform: uppercase;
  }
}
</style>
