<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="resetPassword">
    <validation-observer ref="validationObserver">
      <validation-provider v-slot="{ errors }" mode="lazy" rules="required|email">
        <text-field
          v-model="form.email"
          class="mb-8"
          type="email"
          name="email"
          autocomplete="email"
          :label="$t('label.email')"
          :error-messages="errors"
        />
      </validation-provider>

      <div class="d-flex justify-center">
        <v-btn width="130" link to="/auth/login" large class="primary--text secondary mr-3">
          {{ $t('button.login') }}
        </v-btn>
        <v-btn color="primary" width="190" type="submit" large>{{ $t('button.send_email') }}</v-btn>
      </div>
    </validation-observer>
  </v-form>
</template>

<script>
// Components
import TextField from '@/components/TextField.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ResetPasswordForm',

  components: { TextField, ValidationObserver, ValidationProvider },

  data() {
    return {
      form: {
        email: '',
      },

      isValid: true,
    };
  },

  computed: {},

  methods: {
    async resetPassword() {
      const isValid = await this.$refs.validationObserver.validate();

      if (isValid) {
        this.$emit('submit', { ...this.form });
      }
    },
  },
};
</script>
