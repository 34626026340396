import { AsYouType } from 'libphonenumber-js';

// eslint-disable-next-line
export function parsePhoneNumber(phone) {
  const asYouType = new AsYouType();
  asYouType.input(phone);

  return asYouType.getNumber().number;
}

export function formatPhoneNumber(number) {
  let match = number.match(/^(\+?)(\d{4})(\d{4})(\d{3})$/);

  if (!match) return number;

  match = match.slice(1);

  return match[0] === '+' ? match[0] + match.slice(1).join(' ') : match.join(' ');
}
