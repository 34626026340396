<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="updatePassword">
    <validation-observer ref="validationObserver">
      <validation-provider v-slot="{ errors }" mode="lazy" vid="password" rules="required|password">
        <text-field
          v-model="form.password"
          class="mb-8"
          :type="passwordType"
          name="password"
          :label="$t('label.password')"
          :append-icon="passwordIcon"
          :error-messages="errors"
          @click:append="switchPasswordShow"
        />
      </validation-provider>

      <validation-provider v-slot="{ errors }" mode="lazy" rules="required|confirmed:password">
        <text-field
          v-model="form.passwordСonfirmation"
          class="mb-8"
          :type="passwordConfirmType"
          name="passwordСonfirmation"
          :label="$t('label.password')"
          :append-icon="passwordConfirmIcon"
          :error-messages="errors"
          @click:append="switchPasswordConfirmShow"
        />
      </validation-provider>

      <div class="d-flex justify-center">
        <v-btn width="130" link to="/auth/login" large class="primary--text secondary mr-3">
          {{ $t('button.login') }}
        </v-btn>
        <v-btn color="primary" width="240" type="submit" large>{{ $t('button.update_password') }}</v-btn>
      </div>
    </validation-observer>
  </v-form>
</template>

<script>
// Components
import TextField from '@/components/TextField.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ResetPasswordForm',

  components: { TextField, ValidationObserver, ValidationProvider },

  data() {
    return {
      form: {
        password: '',
        passwordСonfirmation: '',
      },

      isValid: true,

      isVisiblePassword: false,
      isVisiblePasswordConfirm: false,
    };
  },

  computed: {
    passwordIcon() {
      return this.isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    passwordType() {
      return this.isVisiblePassword ? 'text' : 'password';
    },

    passwordConfirmIcon() {
      return this.isVisiblePasswordConfirm ? 'mdi-eye' : 'mdi-eye-off';
    },

    passwordConfirmType() {
      return this.isVisiblePasswordConfirm ? 'text' : 'password';
    },
  },

  methods: {
    switchPasswordShow() {
      this.isVisiblePassword = !this.isVisiblePassword;
    },

    switchPasswordConfirmShow() {
      this.isVisiblePasswordConfirm = !this.isVisiblePasswordConfirm;
    },

    async updatePassword() {
      const isValid = await this.$refs.validationObserver.validate();
      if (isValid) this.$emit('submit', { ...this.form });
    },
  },
};
</script>
